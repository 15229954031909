import { render, staticRenderFns } from "./onebox-login-business-sharedtoken.vue?vue&type=template&id=d4d3361c"
import script from "./onebox-login-business-sharedtoken.vue?vue&type=script&lang=js"
export * from "./onebox-login-business-sharedtoken.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports